
import {
  computed, defineComponent, reactive,
} from 'vue';
import draggable from 'vuedraggable';
import Icone from '@/core/components/UI/Icone.vue';
import MensagemSemDados from '@/core/components/UI/MensagemSemDados.vue';
import { useAppBase } from '@/core/composables/AppBase';
import ComboCaracteristica from '../Caracteristicas/ComboCaracteristica.vue';
import ComboCaracteristicaItem from '../Caracteristicas/ComboCaracteristicaItem.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoCaracteristica } from '@/models/Entidades/IProduto';
import { ETipoCaracteristica } from '@/models/Enumeradores/ETipoCaracteristica';
import { ICaracteristica } from '@/models/Entidades/ICaracteristica';

export default defineComponent({
  name: 'ProdutoCaracteristicas',
  props: {
    caracteristicas: {
      type: Array as () => IProdutoCaracteristica[],
      required: true,
    },
    caracteristicasCadastradas: {
      type: Array as () => ICaracteristica[],
      required: true,
    },
  },
  components: {
    draggable,
    Icone,
    MensagemSemDados,
    ComboCaracteristica,
    ComboCaracteristicaItem,
  },
  emits: ['update:caracteristicas'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useAppBase();

    const state = reactive({
      novaCaracteristica: {} as IProdutoCaracteristica,
      valorNumerico: 0,
      carregandoCaracteristica: false,
    });

    const computedCaracteristicas = computed({
      get: () => props.caracteristicas,
      set: (valor: IProdutoCaracteristica[]) => {
        emit('update:caracteristicas', valor);
      },
    });

    function obtemTipoCaracteristica(codigoCaracteristica: number):number {
      const dadosCaracteristica = props.caracteristicasCadastradas.find((c) => c.codigo === codigoCaracteristica);
      if (dadosCaracteristica !== undefined) {
        return dadosCaracteristica.tipo;
      }
      return 0;
    }

    function verificaPreenchimentoCampoValor(codigoCaracteristica:number):boolean {
      const tipoCaracteristica = obtemTipoCaracteristica(codigoCaracteristica);
      if (tipoCaracteristica === ETipoCaracteristica.Texto
      || tipoCaracteristica === ETipoCaracteristica.Numero
      || tipoCaracteristica === ETipoCaracteristica.Decimal) {
        return true;
      }

      return false;
    }

    function verificaPreenchimentoItem(codigoCaracteristica:number):boolean {
      const tipoCaracteristica = obtemTipoCaracteristica(codigoCaracteristica);
      if (tipoCaracteristica === ETipoCaracteristica.ListaSimples
      || tipoCaracteristica === ETipoCaracteristica.Cores) {
        return true;
      }

      return false;
    }

    function verificaPreenchimentoSubItem(codigoCaracteristica:number):boolean {
      const tipoCaracteristica = obtemTipoCaracteristica(codigoCaracteristica);
      if (tipoCaracteristica === ETipoCaracteristica.ListaSubItens) {
        return true;
      }

      return false;
    }

    function objetoInicialCaracteristica() {
      state.novaCaracteristica = {} as IProdutoCaracteristica;
      state.novaCaracteristica.codigo = 0;
      state.novaCaracteristica.codigoProduto = 0;
      state.novaCaracteristica.valor = '';
    }
    function removerCaracteristica(index: number) {
      computedCaracteristicas.value.splice(index, 1);
    }

    function adicionarCaracteristica() {
      if (!UtilitarioGeral.valorValido(state.novaCaracteristica.codigoCaracteristica)) {
        apresentarMensagemAlerta('Por favor, selecione uma característica!');
        return;
      }

      if (state.novaCaracteristica.tipo === ETipoCaracteristica.Cores
                || state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSimples) {
        if (!UtilitarioGeral.valorValido(state.novaCaracteristica.codigoCaracteristicaItem)) {
          apresentarMensagemAlerta('Por favor, selecione um item da característica!');
          return;
        }
      } else if (state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
        if (!UtilitarioGeral.valorValido(state.novaCaracteristica.codigoCaracteristicaItem)) {
          apresentarMensagemAlerta('Por favor, selecione um item da característica!');
          return;
        }
      }

      if (UtilitarioGeral.validaLista(computedCaracteristicas.value)) {
        if (state.novaCaracteristica.tipo === ETipoCaracteristica.Cores || state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSimples) {
          const produtoCaracteristica = computedCaracteristicas.value.find((c) => c.codigoCaracteristica === state.novaCaracteristica.codigoCaracteristica && c.codigoCaracteristicaItem === state.novaCaracteristica.codigoCaracteristicaItem);
          if (UtilitarioGeral.objetoValido(produtoCaracteristica)) {
            apresentarMensagemAlerta('A característica informada já foi adicionada!');
            return;
          }
        } else if (state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
          const produtoCaracteristica = computedCaracteristicas.value.find((c) => c.codigoCaracteristica === state.novaCaracteristica.codigoCaracteristica
                && c.codigoCaracteristicaItem === state.novaCaracteristica.codigoCaracteristicaItem);
          if (UtilitarioGeral.objetoValido(produtoCaracteristica)) {
            apresentarMensagemAlerta('A característica com sub item informada já foi adicionada!');

            return;
          }
        }
      }

      const produtoCaracteristica:IProdutoCaracteristica = {} as IProdutoCaracteristica;
      produtoCaracteristica.codigo = 0;
      produtoCaracteristica.codigoProduto = 0;
      produtoCaracteristica.codigoCaracteristica = state.novaCaracteristica.codigoCaracteristica;
      produtoCaracteristica.tipo = state.novaCaracteristica.tipo;
      produtoCaracteristica.preenchimento = 1;
      produtoCaracteristica.codigoCaracteristicaItem = 0;
      if (state.novaCaracteristica.tipo === ETipoCaracteristica.Texto
      || state.novaCaracteristica.tipo === ETipoCaracteristica.Numero
      || state.novaCaracteristica.tipo === ETipoCaracteristica.Decimal) {
        produtoCaracteristica.valor = state.novaCaracteristica.valor;
      }

      if (state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSimples
      || state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSubItens
      || state.novaCaracteristica.tipo === ETipoCaracteristica.Cores) {
        produtoCaracteristica.codigoCaracteristicaItem = state.novaCaracteristica.codigoCaracteristicaItem;
      }

      if (state.novaCaracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
        produtoCaracteristica.codigoCaracteristicaItem = state.novaCaracteristica.codigoCaracteristicaItem;
      }

      computedCaracteristicas.value.push(produtoCaracteristica);
      objetoInicialCaracteristica();
    }

    return {
      props,
      ETipoCaracteristica,
      computedCaracteristicas,
      state,
      verificaPreenchimentoCampoValor,
      verificaPreenchimentoItem,
      verificaPreenchimentoSubItem,
      adicionarCaracteristica,
      removerCaracteristica,
      obtemTipoCaracteristica,
    };
  },
});
