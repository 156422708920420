import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_6 = { class: "ant-row" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_combo_regra_sem_estoque = _resolveComponent("combo-regra-sem-estoque")!
  const _component_combo_disponibilidade = _resolveComponent("combo-disponibilidade")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_checkbox, {
      class: "ui-check-card",
      checked: _ctx.computedGerenciarEstoque,
      "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedGerenciarEstoque) = $event))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Gerenciar estoque")
      ]),
      _: 1
    }, 8, ["checked"]),
    (_ctx.computedGerenciarEstoque)
      ? (_openBlock(), _createBlock(_component_card, {
          key: 0,
          titulo: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_a_form_item, { label: "Quantidades" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedQuantidadeEstoque) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.computedQuantidadeEstoque]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_form_item, { label: "Vender múltiplos de" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedVenderMultiplos) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.computedVenderMultiplos]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_form_item, { label: "Regra sem Estoque" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_combo_regra_sem_estoque, {
                      valor: _ctx.computedRegraSemEstoque,
                      "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedRegraSemEstoque) = $event))
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_a_form_item, { label: "Disponibilidade" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_combo_disponibilidade, {
                      valor: _ctx.computedDisponibilidade,
                      "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedDisponibilidade) = $event)),
                      disabled: (_ctx.computedRegraSemEstoque !== 2)
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_card, {
          key: 1,
          titulo: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_a_form_item, { label: "Vender múltiplos de" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedVenderMultiplos) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.computedVenderMultiplos]
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
  ]))
}