
import { defineComponent, reactive, computed } from 'vue';
import ProdutoPrecos from '@/components/Produtos/ProdutoPrecos.vue';
import ProdutoCodigos from '@/components/Produtos/ProdutoCodigos.vue';
import ProdutoEstoque from '@/components/Produtos/ProdutoEstoque.vue';
import ProdutoPesoMedidas from '@/components/Produtos/ProdutoPesoMedidas.vue';
import ProdutoImagens from '@/components/Produtos/ProdutoImagens.vue';
import ProdutoSimuladorFrete from '@/components/Produtos/ProdutoSimuladorFrete.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoDefinicao } from '@/models/Entidades//IProduto';
import { ICaracteristica } from '@/models/Entidades/ICaracteristica';
import { ETipoCaracteristica } from '@/models/Enumeradores/ETipoCaracteristica';
import { useAppBase } from '@/core/composables/AppBase';
import ServicoCaracteristica from '@/servicos/ServicoCaracteristica';

export default defineComponent({
  name: 'ProdutoEditarVariacao',
  props: {
    visivel: {
      type: Boolean,
      required: true,
    },
    descricaoProduto: {
      type: String,
      default: '',
    },
    definicoesProdutos: {
      type: Array as () => IProdutoDefinicao[],
      required: true,
    },
    caracteristicasCadastradas: {
      type: Array as () => ICaracteristica[],
      required: true,
    },
  },
  components: {
    ProdutoImagens,
    ProdutoPrecos,
    ProdutoCodigos,
    ProdutoEstoque,
    ProdutoPesoMedidas,
    ProdutoSimuladorFrete,
  },
  emits: ['update:definicoesProdutos', 'update:visivel'],
  setup(props, { emit }) {
    const servicoCaracteristica = new ServicoCaracteristica();
    const { apresentarMensagemAlerta } = useAppBase();

    const state = reactive({
      produtoDefinicao: {} as IProdutoDefinicao,
      indexDefinicao: 0,
      preparandoCaracteristicas: false,
      computedVisivel: computed({
        get: () => props.visivel,
        set: (val: boolean) => {
          emit('update:visivel', val);
        },
      }),
    });

    function editarVariacao(produtoDefinicao: IProdutoDefinicao, indexDefinicao: number) {
      if (UtilitarioGeral.validaLista(props.caracteristicasCadastradas)) {
        state.produtoDefinicao = UtilitarioGeral.instanciaObjetoLocal(produtoDefinicao);
        state.indexDefinicao = indexDefinicao;
      } else {
        apresentarMensagemAlerta('Nenhuma característica foi encontrada!');
      }
    }

    function confirmarDadosVariacao() {
      const definicoesProdutos = UtilitarioGeral.instanciaObjetoLocal(props.definicoesProdutos);
      definicoesProdutos[state.indexDefinicao] = state.produtoDefinicao;
      emit('update:definicoesProdutos', definicoesProdutos);
      state.computedVisivel = false;
    }

    function montarVariacaoProduto():string {
      return ` ${servicoCaracteristica.montaVariacaoProduto(state.produtoDefinicao.variacoes, props.caracteristicasCadastradas)}`;
    }

    return {
      props,
      state,
      UtilitarioGeral,
      editarVariacao,
      confirmarDadosVariacao,
      ETipoCaracteristica,
      montarVariacaoProduto,
    };
  },
});
