
import {
  computed, defineComponent, reactive,
} from 'vue';
import draggable from 'vuedraggable';
import Icone from '@/core/components/UI/Icone.vue';
import MensagemSemDados from '@/core/components/UI/MensagemSemDados.vue';
import { useAppBase } from '@/core/composables/AppBase';
import ComboProduto from './ComboProduto.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoProdutosRelacionado } from '@/models/Entidades/IProduto';

export default defineComponent({
  name: 'ProdutoRelacionados',
  props: {
    codigoProduto: {
      type: Number,
      required: true,
    },
    produtosRelacionados: {
      type: Array as () => IProdutoProdutosRelacionado[],
      required: true,
    },
  },
  components: {
    draggable,
    Icone,
    MensagemSemDados,
    ComboProduto,
  },
  emits: ['update:produtosRelacionados'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useAppBase();

    const state = reactive({
      codigoProduto: undefined,
      carregandoProduto: false,
    });

    const computedProdutosRelacionados = computed({
      get: () => props.produtosRelacionados,
      set: (valor: IProdutoProdutosRelacionado[]) => {
        emit('update:produtosRelacionados', valor);
      },
    });

    function removerProdutoRelacionado(index: number) {
      computedProdutosRelacionados.value.splice(index, 1);
    }

    function adicionarProdutoRelacionado() {
      if (!UtilitarioGeral.validaCodigo(state.codigoProduto)) {
        apresentarMensagemAlerta('Por favor, selecione um produto!');
        return;
      }

      if (UtilitarioGeral.validaLista(computedProdutosRelacionados.value)) {
        const produtoRelacionadoExistente = computedProdutosRelacionados.value.find((c) => c.codigoProdutoRelacionado === state.codigoProduto);
        if (UtilitarioGeral.objetoValido(produtoRelacionadoExistente)) {
          apresentarMensagemAlerta('O produto informado já foi adicionado!');
          return;
        }
      }

      if (state.codigoProduto !== undefined) {
        const produtoRelacionado:IProdutoProdutosRelacionado = {} as IProdutoProdutosRelacionado;
        produtoRelacionado.codigo = 0;
        produtoRelacionado.codigoProduto = 0;
        produtoRelacionado.codigoProdutoRelacionado = Number(state.codigoProduto);
        produtoRelacionado.ordem = computedProdutosRelacionados.value.length + 1;
        computedProdutosRelacionados.value.push(produtoRelacionado);
      }

      state.codigoProduto = undefined;
    }

    return {
      props,
      computedProdutosRelacionados,
      state,
      adicionarProdutoRelacionado,
      removerProdutoRelacionado,
    };
  },
});
