
import { reactive, defineComponent } from 'vue';
import Card from '@/core/components/UI/Card.vue';
import CampoCep from '@/core/components/UI/CampoCep.vue';
import { IDTOFormaEntregaCalculoFrete } from '@/models/DTO/IDTOFormaEntregaCalculoFrete';
import { IDTOCotarFreteProduto } from '@/models/DTO/IDTOCotarFreteProduto';
import ServicoUtilitario from '@/servicos/ServicoUtilitario';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import MensagemSemDados from '@/core/components/UI/MensagemSemDados.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'ProdutoSimuladorFrete',
  props: {
    codigoProduto: {
      type: Number,
      required: true,
    },
    codigoProdutoDefinicao: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    CampoCep,
    MensagemSemDados,
  },
  setup(props) {
    const state = reactive({
      cotacaoFrete: {} as IDTOCotarFreteProduto,
      opcoesFretes: [] as IDTOFormaEntregaCalculoFrete[],
      buscandoFretes: false,
      mensagemRetornoFrete: '',
    });
    state.opcoesFretes = [];
    const servicoUtilitario = new ServicoUtilitario();
    async function simularFrete() {
      state.buscandoFretes = true;
      state.mensagemRetornoFrete = '';
      state.opcoesFretes = [];
      state.cotacaoFrete.codigoProduto = props.codigoProduto;
      state.cotacaoFrete.codigoProdutoDefinicao = props.codigoProdutoDefinicao;

      state.opcoesFretes = await servicoUtilitario.simularFreteProduto(state.cotacaoFrete);
      if (!UtilitarioGeral.validaLista(state.opcoesFretes)) {
        state.mensagemRetornoFrete = 'Nenhum opção de frete esta disponível para este CEP ou informações preenchidas!';
      }
      state.buscandoFretes = false;
    }
    return {
      props,
      state,
      simularFrete,
      UtilitarioMascara,
    };
  },
});
