
import {
  defineComponent, onBeforeMount, reactive, ref,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IProduto, IProdutoCategoria, IProdutoDefinicao } from '@/models/Entidades/IProduto';
import MensagemSemDados from '@/core/components/UI/MensagemSemDados.vue';
import Icone from '@/core/components/UI/Icone.vue';
import Card from '@/core/components/UI/Card.vue';
import ComboCategoria from '@/components/Produtos/ComboCategoria.vue';
import ComboMarca from '@/components/Produtos/ComboMarca.vue';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import Seo from '@/components/Seo.vue';
import ServicoProduto from '@/servicos/ServicoProduto';
import { IResposta } from '@/core/models/IResposta';
import { IEstrutura } from '@/models/Entidades/IEstrutura';
import { ETipoEstrutura } from '@/models/Enumeradores/ETipoEstrutura';
import ProdutoNovaVariacao from '@/components/Produtos/ProdutoNovaVariacao.vue';
import ProdutoEditarVariacao from '@/components/Produtos/ProdutoEditarVariacao.vue';
import ProdutoPrecos from '@/components/Produtos/ProdutoPrecos.vue';
import ProdutoCodigos from '@/components/Produtos/ProdutoCodigos.vue';
import ProdutoEstoque from '@/components/Produtos/ProdutoEstoque.vue';
import ProdutoPesoMedidas from '@/components/Produtos/ProdutoPesoMedidas.vue';
import ProdutoImagens from '@/components/Produtos/ProdutoImagens.vue';
import ProdutoCaracteristicas from '@/components/Produtos/ProdutoCaracteristicas.vue';
import ProdutoAbasInformativas from '@/components/Produtos/ProdutoAbasInformativas.vue';
import ProdutoRelacionados from '@/components/Produtos/ProdutoRelacionados.vue';
import ProdutoSimuladorFrete from '@/components/Produtos/ProdutoSimuladorFrete.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ICaracteristica } from '@/models/Entidades/ICaracteristica';
import ServicoCaracteristica from '@/servicos/ServicoCaracteristica';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';

export default defineComponent({
  name: 'ProdutoCadastro',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    ComboCategoria,
    ComboMarca,
    ProdutoNovaVariacao,
    ProdutoEditarVariacao,
    ProdutoPrecos,
    ProdutoCodigos,
    ProdutoEstoque,
    ProdutoPesoMedidas,
    ProdutoImagens,
    ProdutoCaracteristicas,
    ProdutoAbasInformativas,
    ProdutoRelacionados,
    ProdutoSimuladorFrete,
    ComunicacaoApi,
    EditorHtml,
    Seo,
  },
  setup() {
    const {
      appBase, apresentarMensagemAlerta, apresentarMensagemSucesso, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();

    const refNovaVariacao = ref<InstanceType<typeof ProdutoNovaVariacao>>();
    const refEditarVariacao = ref<InstanceType<typeof ProdutoEditarVariacao>>();

    const servicoProduto = new ServicoProduto();
    const servicoCaracteristica = new ServicoCaracteristica();

    const state = reactive({
      produto: {} as IProduto,
      categoriasSelecionadas: [] as number[],
      caracteristicasCadastradas: [] as ICaracteristica[],
      apresentarModalVariacao: false,
    });

    async function obterProduto(codigo:number) {
      appBase.carregando = true;

      const produto = await servicoProduto.obter(codigo);
      if (UtilitarioGeral.objetoValido(produto)) {
        for (let index = 0; index < produto.definicoes.length; index += 1) {
          const definicao = produto.definicoes[index];

          for (let p = 0; p < definicao.precos.length; p += 1) {
            const precoProduto = definicao.precos[p];

            if (UtilitarioData.obterAnoData(precoProduto.inicioPromocao) < 2000) {
              precoProduto.inicioPromocao = '';
            }

            if (UtilitarioData.obterAnoData(precoProduto.fimPromocao) < 2000) {
              precoProduto.fimPromocao = '';
            }
          }
        }

        state.categoriasSelecionadas = [];
        if (produto.categorias.length > 0) {
          produto.categorias.forEach((produtoCategoria) => {
            state.categoriasSelecionadas.push(produtoCategoria.codigoCategoria);
          });
        }
      }
      state.produto = produto;
      appBase.carregando = false;
    }

    function instanciarNovaDefinicao(): IProdutoDefinicao {
      const definicao: IProdutoDefinicao = {} as IProdutoDefinicao;
      definicao.codigo = 0;
      definicao.codigoProduto = 0;
      definicao.sku = '';
      definicao.codigoBarras = '';
      definicao.codigoErp = '';
      definicao.gerenciarEstoque = true;
      definicao.venderMultiplos = 0;
      definicao.disponibilidade = 0;
      definicao.regraSemEstoque = 0;
      definicao.quantidadeEstoque = 0;
      definicao.precoSobConsulta = false;
      definicao.peso = 0;
      definicao.altura = 0;
      definicao.largura = 0;
      definicao.comprimento = 0;
      definicao.imagens = [];
      definicao.precos = [];
      definicao.variacoes = [];
      return definicao;
    }
    function limparDados() {
      state.categoriasSelecionadas = [];
      state.produto = {} as IProduto;
      state.produto.codigo = 0;
      state.produto.destaque = false;
      state.produto.possuiVariacao = false;
      state.produto.definicoes = [];
      state.produto.definicoes.push(instanciarNovaDefinicao());
      state.produto.categorias = [];
      state.produto.caracteristicas = [];
      state.produto.abasInformativas = [];
      state.produto.produtosRelacionados = [];
      state.produto.status = true;
      state.produto.estrutura = {} as IEstrutura;
      state.produto.estrutura.codigo = 0;
      state.produto.estrutura.indexar = true;
      state.produto.estrutura.tipo = ETipoEstrutura.Produto;
    }

    function estratagiaVariacoes() {
      if (state.produto.possuiVariacao) {
        state.produto.definicoes = [];
      } else {
        state.produto.definicoes = [];
        state.produto.definicoes.push(instanciarNovaDefinicao());
      }
    }
    function adicionarNovaVariacao() {
      if (refNovaVariacao.value) {
        if (!UtilitarioGeral.valorValido(state.produto.nome)) {
          apresentarMensagemAlerta('É necessário informar o nome do produto antes de criar a variação!');
          return;
        }
        refNovaVariacao.value.adicionarVariacao();
      }
    }

    function editarVariacao(produtoDefinicao: IProdutoDefinicao, index:number) {
      if (refEditarVariacao.value) {
        state.apresentarModalVariacao = true;
        refEditarVariacao.value.editarVariacao(produtoDefinicao, index);
      }
    }

    function excluirVariacao(index:number) {
      state.produto.definicoes.splice(index, 1);
    }

    function montarVariacaoProduto(definicaoProduto: IProdutoDefinicao):string {
      let variacao = '';
      if (UtilitarioGeral.valorValido(definicaoProduto.sku)) {
        variacao += `SKU: ${definicaoProduto.sku}`;
      }
      variacao += servicoCaracteristica.montaVariacaoProduto(definicaoProduto.variacoes, state.caracteristicasCadastradas);
      return variacao;
    }

    onBeforeMount(async () => {
      limparDados();
      state.caracteristicasCadastradas = await servicoCaracteristica.obterTodas();
      const codigoProduto = obterCodigoRota();
      if (codigoProduto > 0) {
        await obterProduto(codigoProduto);
      }
    });

    function preparaPersistenciaCategorias() {
      const produtoCategorias : IProdutoCategoria[] = [];
      if (state.categoriasSelecionadas.length > 0) {
        state.categoriasSelecionadas.forEach((codigoCategoria) => {
          const categoriaExistente = state.produto.categorias.find((c) => c.codigoCategoria === codigoCategoria);
          if (categoriaExistente !== undefined) {
            produtoCategorias.push(categoriaExistente);
          } else {
            const produtoCategoria: IProdutoCategoria = {
              codigo: 0, codigoProduto: state.produto.codigo, codigoCategoria,
            };
            produtoCategorias.push(produtoCategoria);
          }
        });
      }
      state.produto.categorias = produtoCategorias;
    }

    function preparaPersistenciaCaracteristicas() {
      if (UtilitarioGeral.validaLista(state.produto.caracteristicas)) {
        for (let index = 0; index < state.produto.caracteristicas.length; index += 1) {
          state.produto.caracteristicas[index].ordem = (index + 1);
        }
      }
    }

    function preparaPersistenciaAbasInformativas() {
      if (UtilitarioGeral.validaLista(state.produto.abasInformativas)) {
        for (let index = 0; index < state.produto.abasInformativas.length; index += 1) {
          state.produto.abasInformativas[index].ordem = (index + 1);
        }
      }
    }

    function preparaPersistenciaProdutosRelacionados() {
      if (UtilitarioGeral.validaLista(state.produto.produtosRelacionados)) {
        for (let index = 0; index < state.produto.produtosRelacionados.length; index += 1) {
          state.produto.produtosRelacionados[index].ordem = (index + 1);
        }
      }
    }

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações do produto.');
      preparaPersistenciaCategorias();
      preparaPersistenciaCaracteristicas();
      preparaPersistenciaAbasInformativas();
      preparaPersistenciaProdutosRelacionados();
      if (state.produto.codigo === 0) {
        state.produto.estrutura.url = 'url';
        appBase.resposta = await servicoProduto.incluir(state.produto);
      } else {
        appBase.resposta = await servicoProduto.atualizar(state.produto);
      }

      apresentarRespostaRedirecionamento('Produtos');
    }

    function duplicar() {
      appBase.carregando = true;

      const produtoDuplicar = UtilitarioGeral.instanciaObjetoLocal(state.produto);
      produtoDuplicar.codigo = 0;
      produtoDuplicar.nome += ' - Duplicado';
      produtoDuplicar.estrutura.codigo = 0;
      produtoDuplicar.estrutura.identificador = 0;
      produtoDuplicar.estrutura.url = 'url';

      state.produto = produtoDuplicar;
      apresentarMensagemSucesso('Produto duplicado com sucesso! Confira as suas principais informações e clique em Salvar.');
      appBase.carregando = false;
    }

    return {
      appBase,
      state,
      salvar,
      refNovaVariacao,
      refEditarVariacao,
      estratagiaVariacoes,
      adicionarNovaVariacao,
      editarVariacao,
      excluirVariacao,
      montarVariacaoProduto,
      duplicar,
    };
  },
});
