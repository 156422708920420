
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useAppBase } from '@/core/composables/AppBase';
import { IParametrosBuscaRapida } from '@/core/models/Consulta/IParametrosBuscaRapida';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoCaracteristica from '@/servicos/ServicoCaracteristica';
import { IItemBuscaRapida } from '@/core/models/Consulta/IItemBuscaRapida';

export default defineComponent({
  name: 'ComboCaracteristica',
  props: {
    multiplaSelecao: {
      type: Boolean,
      default: false,
    },
    codigo: {
      type: Number || undefined,
    },
    codigos: {
      type: Array as () => number[],
      default: () => [],
    },
    tipoCategoriaSelecionada: {
      type: Number,
    },
    ignorarCodigos: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigo', 'update:codigos', 'update:tipoCategoriaSelecionada', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesBuscaRapida } = useAppBase();

    let debounce = 0;
    const servicoCaracteristica = new ServicoCaracteristica();

    const state = reactive({
      opcoesCaracteristicas: [] as IOption[],
      itensBuscaRapida: [] as IItemBuscaRapida[],
      buscando: false,
      digitando: false,
    });

    async function obterPorCodigo(valor: any) {
      if (state.digitando) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }

      if (props.multiplaSelecao) {
        if (props.codigos.length === 0) { return; }
      } else if (props.codigo === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.itensBuscaRapida)) {
        if (props.multiplaSelecao) {
          const listaCodigosConsulta = state.itensBuscaRapida.map((c) => c.codigo);
          const diferencas = props.codigos.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.itensBuscaRapida.find((c) => c.codigo === props.codigo);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.opcoesCaracteristicas = [];
      const parametrosBuscaRapida: IParametrosBuscaRapida = { apenasAtivos: false };

      if (props.multiplaSelecao) {
        parametrosBuscaRapida.filtrarPorVariosCodigos = true;
        parametrosBuscaRapida.valores = valor;
      } else {
        parametrosBuscaRapida.filtrarPorCodigo = true;
        parametrosBuscaRapida.valor = valor;
      }

      state.itensBuscaRapida = await servicoCaracteristica.buscaRapida(parametrosBuscaRapida);
      state.opcoesCaracteristicas = montaOpcoesBuscaRapida(state.itensBuscaRapida);
      if (!UtilitarioGeral.validaLista(state.opcoesCaracteristicas)) {
        if (props.multiplaSelecao) {
          emit('update:codigos', undefined);
        } else {
          emit('update:codigo', undefined);
        }
      }
    }

    async function pesquisar(valor: any) {
      state.digitando = true;

      state.buscando = true;
      state.opcoesCaracteristicas = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosBuscaRapida: IParametrosBuscaRapida = { valor, apenasAtivos: true };

        state.itensBuscaRapida = await servicoCaracteristica.buscaRapida(parametrosBuscaRapida);
        if (props.ignorarCodigos.length > 0) {
          props.ignorarCodigos.forEach((codigo) => {
            state.itensBuscaRapida = state.itensBuscaRapida.filter((c) => c.codigo !== codigo);
          });
        }
        state.buscando = false;
        state.opcoesCaracteristicas = montaOpcoesBuscaRapida(state.itensBuscaRapida);
      }, 600);
    }

    function obterCaracteristica():any {
      obterPorCodigo(props.codigo);
      return props.codigo;
    }

    const computedCodigo = computed({
      get: () => obterCaracteristica(),
      set: (val: number) => {
        emit('update:codigo', val);
      },
    });

    function obterCaracteristicas():number[] {
      obterPorCodigo(props.codigos);
      return props.codigos;
    }

    const computedCodigos = computed({
      get: () => obterCaracteristicas(),
      set: (val: number[]) => {
        emit('update:codigos', val);
      },
    });

    function changeMultiplos() {
      emit('change', props.codigos);
    }

    function change(codigo:number) {
      const itemSelecionado = state.itensBuscaRapida.find((c) => c.codigo === codigo);
      if (itemSelecionado !== undefined) {
        emit('update:tipoCategoriaSelecionada', Number(itemSelecionado.informacaoAdicional));
      } else {
        emit('update:tipoCategoriaSelecionada', undefined);
      }
      emit('change', props.codigo);
    }

    return {
      props,
      state,
      computedCodigo,
      computedCodigos,
      pesquisar,
      change,
      changeMultiplos,
    };
  },
});
