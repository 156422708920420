
import {
  computed, defineComponent, reactive, onBeforeMount,
} from 'vue';
import Icone from '@/core/components/UI/Icone.vue';
import Card from '@/core/components/UI/Card.vue';
import CampoNumerico from '@/core/components/UI/CampoNumerico.vue';
import SelecionarDataInicialFinal from '@/core/components/UI/SelecionarDataInicialFinal.vue';
import ServicoPreco from '@/servicos/ServicoPreco';
import { IProdutoDefinicaoPreco } from '@/models/Entidades/IProduto';
import { IDTOPreco } from '@/models/DTO/IDTOPreco';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'ProdutoPrecos',
  props: {
    precos: {
      type: Array as () => IProdutoDefinicaoPreco[],
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CampoNumerico,
    SelecionarDataInicialFinal,
  },
  emits: ['update:precos'],
  setup(props, { emit }) {
    const servicoPreco = new ServicoPreco();
    const state = reactive({
      precosCadastrados: [] as IDTOPreco[],
      precosNaoDefinidos: [] as IDTOPreco[],
    });
    state.precosCadastrados = [];
    state.precosNaoDefinidos = [];

    const computedPrecos = computed({
      get: () => props.precos,
      set: (valor: IProdutoDefinicaoPreco[]) => {
        emit('update:precos', valor);
      },
    });

    function verificaVariosPrecos(): boolean {
      return (state.precosCadastrados.length > 1);
    }

    function verificacaoPrecosNaoDefinidos() {
      if (UtilitarioGeral.validaLista(state.precosCadastrados)) {
        const precosCadastrados:IDTOPreco[] = UtilitarioGeral.instanciaObjetoLocal(state.precosCadastrados);
        if (UtilitarioGeral.validaLista(computedPrecos.value)) {
          computedPrecos.value.forEach((precoProduto) => {
            const indexPreco = precosCadastrados.findIndex((c) => c.codigo === precoProduto.codigoPreco);
            if (indexPreco >= 0) {
              precosCadastrados.splice(indexPreco, 1);
            }
          });
        }

        state.precosNaoDefinidos = precosCadastrados.filter((c) => c.status === true);
      } else {
        state.precosNaoDefinidos = [];
      }
    }

    async function removerPreco(index: number) {
      if (index !== -1) {
        computedPrecos.value.splice(index, 1);
      }
      verificacaoPrecosNaoDefinidos();
    }

    function novoPreco(codigoPreco : number) {
      const produtoPreco:IProdutoDefinicaoPreco = {} as IProdutoDefinicaoPreco;
      produtoPreco.codigo = 0;
      produtoPreco.codigoProdutoDefinicao = 0;
      produtoPreco.codigoPreco = codigoPreco;
      produtoPreco.preco = 0;
      produtoPreco.precoPromocao = 0;
      computedPrecos.value.push(produtoPreco);
      verificacaoPrecosNaoDefinidos();
    }

    onBeforeMount(async () => {
      state.precosCadastrados = await servicoPreco.obterTodos();
      verificacaoPrecosNaoDefinidos();
      if (state.precosCadastrados.length === 1) {
        if (!UtilitarioGeral.validaLista(computedPrecos.value) && state.precosCadastrados[0].status) {
          novoPreco(state.precosCadastrados[0].codigo);
        }
      }
    });

    function obterNomePreco(codigoPreco:number): string {
      if (state.precosCadastrados.length === 1) {
        return '';
      }
      const preco = state.precosCadastrados.find((c) => c.codigo === codigoPreco);
      if (preco !== undefined) {
        return preco.nome;
      }

      return '';
    }
    return {
      props,
      computedPrecos,
      state,
      removerPreco,
      novoPreco,
      obterNomePreco,
      verificaVariosPrecos,
    };
  },
});
