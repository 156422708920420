
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/UI/Icone.vue';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IDTOProduto } from '@/models/DTO/IDTOProduto';
import ServicoProduto from '@/servicos/ServicoProduto';
import { IProdutoParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/IProdutoParametrosConsulta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import { IOptionString } from '@/core/models/AntDesign/IOption';

export default defineComponent({
  name: 'ProdutoListagem',
  components: {
    Icone,
    ComunicacaoApi,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta, apresentarMensagemAlerta, apresentarRespostaRedirecionamento,
    } = useAppBase();
    const servicoProduto = new ServicoProduto();

    const state = reactive({
      produtos: [] as IDTOProduto[],
      parametrosConsulta: {} as IProdutoParametrosConsulta,
      apresentarFiltros: false,
      apresentarImportacao: false,
      ordenarPor: 'CodigoProdutoDESC',
      opcoesOrdenacao: [] as IOptionString[],
      listaArquivos: [],
    });
    let debounce = 0;
    let arquivoUpload = new FormData();

    state.opcoesOrdenacao = [];
    const ordemMaisRecentes:IOptionString = { value: 'CodigoProdutoDESC', label: 'Mais recentes' } as IOptionString;
    const ordemNomeAsc:IOptionString = { value: 'NomeProdutoASC', label: 'Nome (A-Z)' } as IOptionString;
    const ordemNomeDesc:IOptionString = { value: 'NomeProdutoDESC', label: 'Nome (Z-A)' } as IOptionString;
    state.opcoesOrdenacao.push(ordemMaisRecentes);
    state.opcoesOrdenacao.push(ordemNomeAsc);
    state.opcoesOrdenacao.push(ordemNomeDesc);

    async function buscarDados() {
      appBase.carregando = true;
      state.apresentarFiltros = false;
      state.apresentarImportacao = false;
      state.produtos = [];
      if (UtilitarioGeral.valorValido(state.ordenarPor)) {
        state.parametrosConsulta.ordenacao = [];
        state.parametrosConsulta.ordenacao.push(state.ordenarPor);
      }

      if (appBase.paginacao.paginaAtual > 0) { state.parametrosConsulta.numeroPagina = appBase.paginacao.paginaAtual; }
      if (appBase.paginacao.qtdeRegistrosPagina > 0) { state.parametrosConsulta.qtdeRegistrosPagina = appBase.paginacao.qtdeRegistrosPagina; }

      const respostaConsulta = await servicoProduto.obterProdutos(state.parametrosConsulta);
      if (UtilitarioGeral.validaLista(respostaConsulta.dados)) {
        state.produtos = respostaConsulta.dados;
        appBase.paginacao = respostaConsulta.paginacao;
      }

      appBase.carregando = false;
    }

    function buscarProdutoPorNome() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarDados();
      }, 600);
    }
    async function limparFiltros() {
      state.parametrosConsulta.status = undefined;
      await buscarDados();
    }
    onBeforeMount(async () => {
      await buscarDados();
    });

    async function paginacaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = current;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }
    async function qtdeRegistrosPaginaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = 1;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }

    async function deletarProduto(codigo: number) {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor...');
      appBase.resposta = await servicoProduto.deletar(codigo);
      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        const index = state.produtos.findIndex((u) => u.codigo === codigo);
        if (index >= 0) {
          state.produtos.splice(index, 1);
        }
      }
      apresentarResposta();
    }
    function confirmaExclusao(Produto:IDTOProduto) {
      Modal.confirm({
        title: 'Você confirma a exclusão do produto:',
        content: `${Produto.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await deletarProduto(Produto.codigo); },
      });
    }

    function removerArquivo(file:never) {
      const index = state.listaArquivos.indexOf(file);
      const newFileList = state.listaArquivos.slice();
      newFileList.splice(index, 1);
      state.listaArquivos = newFileList;
    }

    async function importarArquivo() {
      if (!UtilitarioGeral.objetoValido(state.listaArquivos)) {
        apresentarMensagemAlerta('É necessário selecionar um arquivo para realizar a importação!');
        return;
      }

      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos importando os seus produtos.');

      appBase.resposta = await servicoProduto.importarProdutos(arquivoUpload);
      state.apresentarImportacao = false;
      state.listaArquivos = [];
      await buscarDados();
    }

    function prepararArquivoUpload(arquivo:any) {
      arquivoUpload = new FormData();
      arquivoUpload.append('arquivo', arquivo);
    }
    return {
      appBase,
      state,
      UtilitarioMascara,
      buscarDados,
      limparFiltros,
      buscarProdutoPorNome,
      paginacaAlterada,
      qtdeRegistrosPaginaAlterada,
      confirmaExclusao,
      removerArquivo,
      importarArquivo,
      prepararArquivoUpload,
    };
  },
});
