
import { computed, defineComponent } from 'vue';
import Card from '@/core/components/UI/Card.vue';
import Icone from '@/core/components/UI/Icone.vue';

export default defineComponent({
  name: 'ProdutoCodigos',
  props: {
    sku: {
      type: String,
      required: true,
    },
    codigoBarras: {
      type: String,
      required: true,
    },
    codigoErp: {
      type: String,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
  },
  emits: ['update:sku', 'update:codigoBarras', 'update:codigoErp'],
  setup(props, { emit }) {
    const computedSku = computed({
      get: () => props.sku,
      set: (valor: string) => {
        emit('update:sku', valor);
      },
    });

    const computedCodigoBarras = computed({
      get: () => props.codigoBarras,
      set: (valor: string) => {
        emit('update:codigoBarras', valor);
      },
    });

    const computedCodigoErp = computed({
      get: () => props.codigoErp,
      set: (valor: string) => {
        emit('update:codigoErp', valor);
      },
    });

    return {
      props,
      computedSku,
      computedCodigoBarras,
      computedCodigoErp,
    };
  },
});
