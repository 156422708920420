import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      onChange: _ctx.defineValorSelecionado,
      class: "ui-full",
      disabled: _ctx.props.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select_option, { value: 0 }, {
          default: _withCtx(() => [
            _createTextVNode("Disponibilidade imediata")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 1 }, {
          default: _withCtx(() => [
            _createTextVNode("1 dia útil")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 2 }, {
          default: _withCtx(() => [
            _createTextVNode("2 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 3 }, {
          default: _withCtx(() => [
            _createTextVNode("3 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 4 }, {
          default: _withCtx(() => [
            _createTextVNode("4 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 5 }, {
          default: _withCtx(() => [
            _createTextVNode("5 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 6 }, {
          default: _withCtx(() => [
            _createTextVNode("6 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 7 }, {
          default: _withCtx(() => [
            _createTextVNode("7 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 8 }, {
          default: _withCtx(() => [
            _createTextVNode("8 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 9 }, {
          default: _withCtx(() => [
            _createTextVNode("9 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 10 }, {
          default: _withCtx(() => [
            _createTextVNode("10 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 15 }, {
          default: _withCtx(() => [
            _createTextVNode("15 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 20 }, {
          default: _withCtx(() => [
            _createTextVNode("20 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 25 }, {
          default: _withCtx(() => [
            _createTextVNode("25 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 30 }, {
          default: _withCtx(() => [
            _createTextVNode("35 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 45 }, {
          default: _withCtx(() => [
            _createTextVNode("45 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 60 }, {
          default: _withCtx(() => [
            _createTextVNode("60 dias úteis")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 90 }, {
          default: _withCtx(() => [
            _createTextVNode("90 dias úteis")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "onChange", "disabled"])
  ]))
}