
import {
  computed, defineComponent, reactive,
} from 'vue';
import draggable from 'vuedraggable';
import Icone from '@/core/components/UI/Icone.vue';
import MensagemSemDados from '@/core/components/UI/MensagemSemDados.vue';
import { useAppBase } from '@/core/composables/AppBase';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import { IProdutoAbasInformativa } from '@/models/Entidades/IProduto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'ProdutoAbasInformativas',
  props: {
    abasInformativas: {
      type: Array as () => IProdutoAbasInformativa[],
      required: true,
    },
  },
  components: {
    draggable,
    Icone,
    EditorHtml,
    MensagemSemDados,
  },
  emits: ['update:abasInformativas'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useAppBase();

    const state = reactive({
      tituloEdicao: '',
      conteudoEdicao: '',
      indexAbaEdicao: 0,
      apresentarEdicao: false,
    });

    const computedAbasInformativas = computed({
      get: () => props.abasInformativas,
      set: (valor: IProdutoAbasInformativa[]) => {
        emit('update:abasInformativas', valor);
      },
    });

    function removerAba(index: number) {
      computedAbasInformativas.value.splice(index, 1);
    }

    function adicionarAba() {
      const produtoCaracteristica:IProdutoAbasInformativa = {} as IProdutoAbasInformativa;
      produtoCaracteristica.codigo = 0;
      produtoCaracteristica.codigoProduto = 0;
      produtoCaracteristica.titulo = 'Novo título';
      produtoCaracteristica.conteudo = '';
      produtoCaracteristica.ordem = 0;

      computedAbasInformativas.value.push(produtoCaracteristica);
    }

    function confirmarEdicaoAba() {
      if (!UtilitarioGeral.valorValido(state.tituloEdicao)) {
        apresentarMensagemAlerta('Preencha o título da Aba!');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.conteudoEdicao)) {
        apresentarMensagemAlerta('Preencha a descrição da Aba!');
        return;
      }

      computedAbasInformativas.value[state.indexAbaEdicao].titulo = state.tituloEdicao;
      computedAbasInformativas.value[state.indexAbaEdicao].conteudo = state.conteudoEdicao;
      state.indexAbaEdicao = 0;
      state.tituloEdicao = '';
      state.conteudoEdicao = '';
      state.apresentarEdicao = false;
    }

    function apresentarEdicaAba(index: number, abaInformativa:IProdutoAbasInformativa) {
      state.indexAbaEdicao = index;
      state.tituloEdicao = abaInformativa.titulo;
      state.conteudoEdicao = abaInformativa.conteudo;
      state.apresentarEdicao = true;
    }

    return {
      props,
      computedAbasInformativas,
      state,
      adicionarAba,
      removerAba,
      confirmarEdicaoAba,
      apresentarEdicaAba,
    };
  },
});
