import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.props.multiplaSelecao)
      ? (_openBlock(), _createBlock(_component_a_select, {
          key: 0,
          class: "ui-full",
          value: _ctx.computedCodigos,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigos) = $event)),
          "show-search": "",
          mode: "multiple",
          allowClear: true,
          placeholder: _ctx.props.placeholder,
          optionFilterProp: "label",
          filterOption: false,
          "not-found-content": _ctx.state.buscando ? undefined : null,
          options: _ctx.state.opcoesMarcas,
          disabled: _ctx.props.disabled,
          onChange: _ctx.change,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pesquisar(''))),
          onSearch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pesquisar($event))),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.state.digitando = false))
        }, _createSlots({ _: 2 }, [
          (_ctx.state.buscando)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createTextVNode(" Buscando "),
                  _createVNode(_component_a_spin)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "placeholder", "not-found-content", "options", "disabled", "onChange"]))
      : (_openBlock(), _createBlock(_component_a_select, {
          key: 1,
          class: "ui-full",
          value: _ctx.computedCodigo,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedCodigo) = $event)),
          "show-search": "",
          allowClear: true,
          placeholder: _ctx.props.placeholder,
          optionFilterProp: "label",
          filterOption: false,
          "not-found-content": _ctx.state.buscando ? undefined : null,
          options: _ctx.state.opcoesMarcas,
          disabled: _ctx.props.disabled,
          onChange: _ctx.change,
          onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.pesquisar(''))),
          onSearch: _cache[6] || (_cache[6] = ($event: any) => (_ctx.pesquisar($event))),
          onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.state.digitando = false))
        }, _createSlots({ _: 2 }, [
          (_ctx.state.buscando)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createTextVNode(" Buscando "),
                  _createVNode(_component_a_spin)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "placeholder", "not-found-content", "options", "disabled", "onChange"]))
  ]))
}