import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ace607f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-full" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "ant-row" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_data_inicial_final = _resolveComponent("selecionar-data-inicial-final")!
  const _component_card = _resolveComponent("card")!
  const _component_a_form = _resolveComponent("a-form")!

  return ((_ctx.state.precosCadastrados.length> 0))
    ? (_openBlock(), _createBlock(_component_card, {
        key: 0,
        titulo: "Preços"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            ((_ctx.state.precosNaoDefinidos.length > 0))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.precosNaoDefinidos, (precoNaoDefinido) => {
                    return (_openBlock(), _createElementBlock("span", {
                      class: "ui-definir-preco",
                      onClick: ($event: any) => (_ctx.novoPreco(precoNaoDefinido.codigo)),
                      key: precoNaoDefinido.codigo
                    }, [
                      _createVNode(_component_icone, { nome: "dinheiro" }),
                      _createTextVNode(" Definir Preço: " + _toDisplayString(precoNaoDefinido.nome), 1)
                    ], 8, _hoisted_3))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_form, { layout: "vertical" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedPrecos, (produtoPreco, index) => {
                  return (_openBlock(), _createBlock(_component_card, {
                    key: index,
                    titulo: _ctx.obterNomePreco(produtoPreco.codigoPreco),
                    classTitulo: "ui-card-info-titulo-pequeno",
                    classBorda: _ctx.verificaVariosPrecos()? 'ui-card-info':''
                  }, {
                    AcoesExtra: _withCtx(() => [
                      ((_ctx.state.precosCadastrados.length> 1))
                        ? (_openBlock(), _createBlock(_component_a_tooltip, {
                            key: 0,
                            title: "Rmover definições deste preço",
                            color: "red",
                            placement: "top"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("a", {
                                class: "ui-remover-preco",
                                onClick: ($event: any) => (_ctx.removerPreco(index))
                              }, [
                                _createVNode(_component_icone, { nome: "excluir" })
                              ], 8, _hoisted_4)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_a_form_item, { label: "Preço" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_campo_numerico, {
                                monetario: true,
                                valor: produtoPreco.preco,
                                "onUpdate:valor": ($event: any) => ((produtoPreco.preco) = $event)
                              }, null, 8, ["valor", "onUpdate:valor"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_a_form_item, { label: "Preço Promocional" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_campo_numerico, {
                                monetario: true,
                                valor: produtoPreco.precoPromocao,
                                "onUpdate:valor": ($event: any) => ((produtoPreco.precoPromocao) = $event)
                              }, null, 8, ["valor", "onUpdate:valor"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_a_form_item, { label: "Data de Início e fim da Promoção" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_selecionar_data_inicial_final, {
                                horaMinutoSegundo: true,
                                dataInicialSelecionada: produtoPreco.inicioPromocao,
                                "onUpdate:dataInicialSelecionada": ($event: any) => ((produtoPreco.inicioPromocao) = $event),
                                dataFinalSelecionada: produtoPreco.fimPromocao,
                                "onUpdate:dataFinalSelecionada": ($event: any) => ((produtoPreco.fimPromocao) = $event)
                              }, null, 8, ["dataInicialSelecionada", "onUpdate:dataInicialSelecionada", "dataFinalSelecionada", "onUpdate:dataFinalSelecionada"])
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["titulo", "classBorda"]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}