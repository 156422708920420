
import { computed, defineComponent } from 'vue';
import { Money3Component } from 'v-money3';
import { IConfiguracaoVMoney } from '@/core/models/Mascara/IConfiguracaoVMoney';

export default defineComponent({
  name: 'CampoNumericoMedida',
  components: {
    money3: Money3Component,
  },
  props: {
    valor: {
      type: Number,
      default: 0,
    },
    quantidadeCasasDecimais: {
      type: Number,
    },
    centimetro: {
      type: Boolean,
      default: false,
    },
    peso: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor'],
  setup(props, { emit }) {
    function obterConfiguracao(quantidadeCasasDecimaisPadrao?:number):IConfiguracaoVMoney {
      const configuracaoVMoney:IConfiguracaoVMoney = {} as IConfiguracaoVMoney;
      configuracaoVMoney.masked = false;
      configuracaoVMoney.prefix = '';
      configuracaoVMoney.prefix = '';
      configuracaoVMoney.suffix = '';
      configuracaoVMoney.thousands = '.';
      configuracaoVMoney.decimal = ',';
      if (props.quantidadeCasasDecimais !== undefined && props.quantidadeCasasDecimais !== null) {
        configuracaoVMoney.precision = props.quantidadeCasasDecimais;
      } else if (quantidadeCasasDecimaisPadrao !== undefined && quantidadeCasasDecimaisPadrao !== null) {
        configuracaoVMoney.precision = quantidadeCasasDecimaisPadrao;
      } else {
        configuracaoVMoney.precision = 0;
      }
      configuracaoVMoney.disableNegative = true;
      configuracaoVMoney.disabled = props.disabled;
      configuracaoVMoney.allowBlank = false;
      configuracaoVMoney.minimumNumberOfCharacters = 0;

      return configuracaoVMoney;
    }
    const computedValor = computed({
      get: () => props.valor,
      set: (valor: number) => {
        emit('update:valor', valor);
      },
    });

    return {
      props,
      computedValor,
      obterConfiguracao,
    };
  },
});
